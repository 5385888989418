<template>
  <div>
    <campaign-form
      ref="form"
      :loading="loading"
      :editing="isEditing"
      :settings="formSettings"
      :campaign-presets="campaignPresets"
      :methods="methods"
      :collections="getCollectionsForCampaignForm"
      :links="links"
      :globals="!isUpsales && globals"
      @back="back()"
      @save="save($event)"
    >
      <template
        #ad-form="{
          isCreating, adFormatType, adFormatSize, saveHandler
        }"
      >
        <ad-form
          v-if="isCreating"
          :ad-format="adFormatType"
          is-campaign-form
          :preseted-size="adFormatSize"
          @saved="saveHandler"
        />
      </template>
      <template #conversion-test>
        <conversion-test />
      </template>
    </campaign-form>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { CampaignForm } from '@clickadilla/components/CampaignForm/index.js'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import {
    adsRepository,
    appsRepository,
    campaignGroupsRepository,
    campaignsRepository,
    categoriesRepository,
    citiesRepository,
    ipRangesRepository,
    priceboxRepository,
    providersRepository,
    regionsRepository,
    sitesRepository
  } from '@/services/repository-factory.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import Campaign from '@/services/classes/Campaign.js'
  import handleErrors from '@/services/handleErrors.js'
  import routeNames from '@/types/route-names.js'
  import guardSections from '@/types/guard-sections.js'
  import ConversionTest from '@/components/ConversionTest/Index.vue'
  import googleAnalytics from '@/plugins/google-analytics.js'

  const { getGoogleAnalyticsSessionCount } = googleAnalytics

  export default {
    name: 'ClientCampaignForm',
    components: {
      ConversionTest,
      AdForm: () => import('@/components/ads/AdForm/Index.vue'),
      CampaignForm
    },
    props: {
      id: {
        type: [Number, String],
        default: 0
      },
      offerCampaign: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        loading: false,
        campaignPresets: null,
        googleSessionCount: 0,
        methods: {
          findPremiumSites: (...args) => sitesRepository.findPremium(...args),
          fetchMobileApps: (params) => appsRepository.fetchMobileApps(params, { campaignId: this.id }),
          fetchAvailableCategoryGroups: (...args) => categoriesRepository.fetchAvailableCategoryGroups(...args),
          availableTrafficForCampaign: (...args) => priceboxRepository.availableTrafficForCampaign(...args),
          parseIpRangesString: (...args) => ipRangesRepository.parse(...args),
          findRegions: (...args) => regionsRepository.find(...args),
          findCities: (...args) => citiesRepository.find(...args),
          fetchSelectedSites: (...args) => sitesRepository.byIds(...args),
          findSite: (...args) => (this.adNetwork.showSiteDomains
            ? sitesRepository.findBlackWhiteList(...args)
            : sitesRepository.searchById(...args)),
          findMultipleSites: (...args) => sitesRepository.validateSites(...args),
          fetchCampaignGroups: (...args) => campaignGroupsRepository.index(...args),
          storeCampaignGroup: (...args) => campaignGroupsRepository.store(...args),
          fetchAdsForCampaign: (params) => adsRepository.forCampaignForm(params),
          gtmPush: (params) => gtmPush(params),
          createCampaign: (params) => campaignsRepository.store(params),
          updateCampaign: (params) => campaignsRepository.update(this.id, params),
          fetchProviders: (params) => providersRepository.list(params),
          handleErrors: (errors) => handleErrors(errors),
          openLiveChat: () => this.openLiveChat()
        },
        globals: {
          route: this.$route,
          router: this.$router
        }
      }
    },
    computed: {
      ...mapGetters('settings', [
        'helpUrls',
        'getAdFormatsByAdNetwork',
        'defaultUniquesTypes'
      ]),
      ...mapGetters('trafficChart', ['trafficChartPresets']),
      ...mapGetters('collections', ['getCollectionsForCampaignForm']),
      ...mapState('personal', ['trafficProviderSubscription']),
      ...mapState('settings', ['adNetwork', 'trafficQualityTypesName', 'maxSessionCount']),
      hasUpsalesAccess() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.CAMPAIGN_OFFERS)
          && this.googleSessionCount < this.maxSessionCount
      },
      measurementId() {
        return this.adNetwork.googleAnalyticsMeasurementId
      },
      formSettings() {
        return {
          adFormats: this.getAdFormatsByAdNetwork,
          campaignFormView: this.adNetwork.settings.campaignFormView,
          limitTypesByPricingModel: this.$store.state.settings.limitTypesByPricingModel,
          placementTypes: this.$store.state.settings.placementTypes,
          showSiteDomains: this.adNetwork.showSiteDomains,
          defaultUniquesTypes: this.defaultUniquesTypes,
          connectionTypes: this.$store.state.settings.connectionTypes,
          minLimitInMoney: this.$store.state.settings.minLimitInMoney,
          minLimitInImpressions: this.$store.state.settings.minLimitInImpressions,
          minLimitInClicks: this.$store.state.settings.minLimitInClicks,
          maxWebPushSubscriptionDays: this.$store.state.settings.maxWebPushSubscriptionDays,
          trafficQualityTypesName: this.trafficQualityTypesName,
          trafficProviderSubscription: this.trafficProviderSubscription,
          adNetworkName: this.adNetwork.name,
          defaultAdFormatTargets: this.adNetwork.settings.defaultAdFormatTargets,
          orderAdFormatSortingGroups: this.adNetwork.settings.orderAdFormatSortingGroups
        }
      },
      links() {
        return {
          cpcLimits: this.helpUrls.cpc_limits,
          campaignLimits: this.helpUrls.campaign_limits,
          smoothSpendRate: this.helpUrls.smooth_spend_rate,
          inStreamPlacementTypes: this.helpUrls.in_stream_placement_types,
          bannerPlacementTypes: this.helpUrls.banner_placement_types,
          createCpaCampaign: this.helpUrls.create_cpa_campaign,
          cpcCampaignIsPaused: this.helpUrls.cpc_campaign_is_paused,
          trafficCost: this.helpUrls.traffic_cost,
          autoAdjustHelp: this.helpUrls.auto_adjust_help,
          trackConversions: this.helpUrls.track_conversions,
          popunderAdsCampaign: this.helpUrls.popunder_ads_campaign,
          bannerAdsCampaign: this.helpUrls.banner_ads_campaign,
          webPushNotificationAds: this.helpUrls.web_push_notification_ads,
          inPagePushAds: this.helpUrls.in_page_push_ads,
          inStreamVideoAds: this.helpUrls.in_stream_video_ads,
          iosCalendarAds: this.helpUrls.ios_calendar_ads,
          nativeAds: this.helpUrls.native_ads,
          tabDirectLinkAds: this.helpUrls.tab_direct_link_ads,
          galleryAds: this.helpUrls.gallery_ads,
          fullPageInterstitial: this.helpUrls.full_page_interstitial,
          pricingModels: this.helpUrls.pricing_models,
          cpaCampaigns: this.helpUrls.cpa_campaigns,
          paymentVerification: this.helpUrls.payment_verification
        }
      },
      isEditing() {
        return this.$route.name === routeNames.EDIT_CAMPAIGN
      },
      isDuplicate() {
        return this.$route.name === routeNames.DUPLICATE_CAMPAIGN
      },
      isUpsales() {
        return this.$route.name === routeNames.OFFER_CAMPAIGN
      },
      routePath() {
        return this.$route.name
      }
    },
    watch: {
      routePath: {
        immediate: true,
        handler: 'prepareForm'
      }
    },
    async created() {
      if (this.$route.query.trafficChartsPresets) {
        this.campaignPresets = this.trafficChartPresets
      }
      await this.fetchUserProfile()
      await this.getGoogleSessionCount()

      this.fetchCollectionsForCampaignForm()
    },
    methods: {
      ...mapActions('collections', ['fetchCollectionsForCampaignForm']),
      ...mapActions('personal', ['fetchUserProfile']),
      prepareForm() {
        if (this.$refs.form) {
          this.$refs.form.reset()
        }
        if (this.id) {
          this.fetchCampaign(this.id)
        } else {
          gtmPush({
            event: GTM_EVENTS.START_CREATE_CAMPAIGN,
            start_create_campaign: Date.now()
          })
        }
        if (this.offerCampaign) {
          this.loading = true
          this.$nextTick(() => {
            this.$refs.form.setCampaignFormData(this.offerCampaign)
            this.loading = false
          })
        }
      },
      async fetchCampaign() {
        this.loading = true
        const response = await campaignsRepository.show(this.id)
        const campaign = new Campaign(response).getCampaignFormParams
        this.$refs.form.setCampaignFormData(campaign)
        this.loading = false
      },
      sendGtm(campaignId) {
        const editSuccessEvent = this.isEditing && GTM_EVENTS.CAMPAIGN_CREATED_EDIT
        const duplicateSuccessEvent = this.isDuplicate && GTM_EVENTS.CAMPAIGN_CREATED_DUPLICATE
        const upsaleSuccessEvent = this.isUpsales && GTM_EVENTS.CAMPAIGN_CREATED_UPSALE
        const defaultSuccessEvent = GTM_EVENTS.CAMPAIGN_CREATED_SUCCESSFULLY

        const event = editSuccessEvent
          || duplicateSuccessEvent
          || upsaleSuccessEvent
          || defaultSuccessEvent

        gtmPush({
          event,
          ...upsaleSuccessEvent && {
            campaign_id: campaignId
          }
        })
      },
      async save(campaignId) {
        this.sendGtm(campaignId)

        if (this.isEditing) {
          this.$showSuccessNotification(this.$t('main.campaign_saved'))
          await this.$router.push({ name: routeNames.CAMPAIGN, params: { id: this.id } })
        } else if (this.hasUpsalesAccess) {
          await this.$router.push({ name: routeNames.UPSALE, params: { campaignId } })
        } else {
          this.$showSuccessNotification(this.$t('main.campaign_created'))
          await this.$router.push({ name: routeNames.CAMPAIGNS })
        }
      },
      back() {
        if (this.isEditing) {
          this.$router.push({
            name: routeNames.CAMPAIGN,
            params: {
              id: this.id
            }
          })
        } else {
          this.$router.push({ name: routeNames.CAMPAIGNS })
        }
      },
      async getGoogleSessionCount() {
        this.googleSessionCount = await getGoogleAnalyticsSessionCount(this.measurementId)
      },
      openLiveChat() {
        const { liveChat } = this.adNetwork.settings
        if (liveChat.type === 'intercom') {
          this.$intercom.show()
        } else if (liveChat.type === 'tawk') {
          this.$tawk.toggleChat()
        } else if (liveChat.type === 'dashly') {
          this.$dashly.openChat()
        }
      }
    }
  }
</script>
